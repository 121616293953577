@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700;800&family=Roboto:wght@400;500&display=swap');

.androidNotifContainer{
    width: 335px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 0.664039px rgba(0, 0, 0, 0.211221), 0px 1.32808px 0.664039px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: flex-start;
    padding: 15px;
    flex-direction: column;

}

.androidHeader{
    font-family: 'Roboto', serif;
    font-size: 11px;
    line-height: 13px;
    color: #212121;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.androidBody{
    font-family: 'Roboto', serif;
    color: #212121;
    font-size: 13px;
    text-align: left;
    margin-bottom: 15px;
}

.androidTitle{
    font-weight: 500;
    word-break: break-word;
    white-space: pre-wrap;
}


.androidMessage{
    font-weight: 400;
    word-break: break-word;
    white-space: pre-wrap;
}

.androidIcon{
    width: 16px;
    height: 16px;
    margin-right: 7px;
}

.iosNotifContainer{
    width: 360px;
    height: auto;
    background-color: #fefefe;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 15px 9px;
    box-shadow: 0px 0px 0.664039px rgba(0, 0, 0, 0.211221), 0px 1.32808px 0.664039px rgba(0, 0, 0, 0.12);
}

.iosIcon{
    width: 38px;
    height: 38px;
    border-radius: 6px;
    
}

.iosText{
    font-family: 'Roboto', serif;
    font-size: 15px;
    width: 292px;
    color: #222222;
    text-align: left;
    margin-left: 10px;
}

.iosTitle{
    font-weight: 500;
    white-space: pre-wrap;
}

.iosMessage{
    font-weight: 400;
    letter-spacing: -0.05em;
    word-break: break-word;
    white-space: pre-wrap;
}

.iosTimeStamp{
    font-family: 'Roboto', serif;
    font-size: 12px;
    font-weight: 500;
    color: #3F3F3F;
    opacity: 50%;
    margin-right: 10px;
}

.alert-banner-container {
    width: 100%;
    background-color: #EB0A1E;
    position: relative;
    overflow: auto;
    border-bottom: 0.5px solid white;
    display: flex; 
}

.alert-banner-container .text {
    color: #FFFFFF;
    padding: 10px 15px 15px 50px;
    flex-grow: 1; 
}
.alert-banner-container .text .header-text {
    font: 20px "ToyotaType-Bold", sans-serif;
    font-size: 16px; 
    text-align: left;

}
.alert-banner-container .text .message-text {
    font-size: 13px;
    padding-top: 10px; 
    text-align: left;
    display: block;
    word-break: break-word;
}
.alert-banner-container .text .dismiss-icon {
    float: right; 
}
.alert-banner-container .text .dismiss-icon img {
    height: 15px;
    padding-left: 5px;
    padding-bottom: 2px; 
}

.announcementContainer{
    display:inline-block;
    text-align: left;
    padding: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: #FFFFFF;
    position: relative;
}

.desktopContainer{
    width: 775px;
    display:flex;
    justify-content: space-between;
}

.tabletContainer{
    width: 376px;
    display:flex;
    justify-content: space-between;
}

.mobileContainer{
    width: 315px;
    display:flex;
    justify-content: space-between;
}

.desktopAnnouncementText{
    width: 665px;
    font-size: 15px;
}

.tabletAnnouncementText{
    width: 275px;
    font-size: 15px;
}

.mobileAnnouncementText{
    width: 215px;
    font-size: 15px;
}

.announcementTitle{
    word-break: break-word;
    font-weight: 700;
}

.announcementTitle span, .announcementMessage span {
    white-space: pre-wrap;
}

.announcementMessage{
    font-weight: 400;
    margin-top: 15px;
    letter-spacing: -0.05em;
    word-break: break-word;
}
.announcementMessage a {
    color: #EB0A1E;
    text-decoration: none;
}
.announcementMessage a:hover {
    text-decoration: underline;
}

.announcementTimestamp{
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #CCCCCC;
    opacity: 0.5;
    margin-right: 10px;
}



.announcementIcon{
    width: 20px;
    height: 20px;
}

.truncate-text-one-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    height: 20px;
}

.see-less-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.see-more, .see-less {
    display: inline;
    cursor: pointer;
    color: #EB0A1E;
    font-family: 'Archivo', serif;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    float: right;
}

.redirect-button {
    background-color: #FFFFFF;
    border: 2px solid #000000;
    color: #000000;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    right: 30px;
    border-radius: 10px;
    padding: 2px 16px;
}