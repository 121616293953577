.glossary-container {
  margin: 50px auto;
  padding: 0 30px;
  max-width: 1400px;
}
.select-input {
  text-align: left;
}
.button-label {
  margin-left: 8px;
}
.filter-result {
  margin-top: 30px;
  font-size: large;
  text-align: left;
}
.filter-result button {
  margin-right: 30px;
}
.card {
  margin-top: 25px;
  padding: 15px 15px 25px;
}
.column {
  padding: 12px;
  align-items: center;
}
.info {
  font-size: large;
  text-align: left;
}
.info .term {
  font-weight: bold;
}
.info .category {
  margin-top: 10px;
  font-style: italic;
}
.info .details {
  margin-top: 10px;
  font-size: x-small;
  color: slategray;
}
.content {
  text-align: left;
}
.content .header {
  font-weight: bold;
}
.content .featureAvailability {
  text-decoration: none;
  font-weight: bold;
}
.content .disclaimer {
  font-style: italic;
  font-size: small;
}
.content .notes {
  font-style: italic;
}
.image-wrapper {
  position: relative;
  max-height: 100px;
  width: 100px;
}
.delete-image-button {
  color: red;
  text-shadow: 0 0 2px white;
  position: absolute;
  margin-left: 5px;
}
.delete-image-button:hover {
  cursor: pointer;
}
.caption {
  max-width: 100px;
  overflow-wrap: break-word;
  margin-top: 5px;
  font-size: x-small;
  color: slategray;
}
.textfield {
  margin-bottom: 10px !important;
}
.dropzone {
  margin-top: 10px;
  padding: 10px;
  width: auto;
}
.column .header {
  font-weight: bold;
  text-align: left;
  margin-bottom: 3px;
}
.actions .button {
  width: 100%;
  margin-bottom: 10px;
}
